<template>
  <b-container fluid>
    <b-row :key="orgId">
      <b-col md="12" class="heading_alignment">
        <iq-card style="padding-left:20px; padding-right:20px" class="pt-3">
          <template v-slot:headerTitle>
            <h4 class="card-title">
              <i class="fa-solid fa-calendar" aria-hidden="true" style="font-size: 20px; margin-right: 10px; color: var(--iq-primary);"></i>
              {{cvCardTitle}}
            </h4>
            <span v-if="org_name">
                {{org_name}}{{org_city ? ', ' + org_city : '' }}
                {{(org_state && org_state != "null") ? ', ' + org_state : '' }}
                {{org_country ? ', ' + org_country : '' }}<br>
                <small>Id: {{ orgId }}</small>
            </span>
          </template>
          <template v-slot:body>
            <b-row class="mr-0" w-100 >
              <b-col class="col-12 col-sm-12 col-md-6 col-lg-7 mb-2">
                <b-form-input
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"
                ></b-form-input>
              </b-col>
              <b-col class="col-12 col-sm-7 col-md-2 col-lg-2 mb-2">
                <VueJsonToCsv v-if="eventList && eventList.length" :json-data="eventList" csv-title="userDocument" >
                  <b-btn class="Download_button" variant="primary"> Download
                  </b-btn>
                </VueJsonToCsv>
              </b-col>
              <b-col class="w-100 col-12 col-sm-5 col-md-4 col-lg-3 mb-2">
                <div class="dateRange">
                  <date-range-picker ref="picker" :show-dropdowns="true" :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }" @update="eventSchoolListDateWise()"  v-model="whereFilter.dateRange" ></date-range-picker>
                </div>
              </b-col>
            </b-row>
            <b-row class="container_minheight">
              <b-col md="12" v-if="eventList && eventList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="eventList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentPage"
                  :per-page="perPage"
                >
                  <template v-slot:cell(sno)="data">
                    {{data.index +1 }}
                  </template>

                  <template v-slot:cell(event_name)="data">
                    <span class="pointer" @click="gotoEvent(data.item.event_id)">{{data.item.event_name}}</span><br>
                    <small>Id:&nbsp;{{data.item.event_id}}</small>
                    <div>
                      <i class="ri-parent-fill pointer ml-2 font-size-20 mt-1" title="View Registered Users" @click="goToEventParticipantDashboard(data.item)"></i>
                    </div>
                  </template>

                  <template v-slot:cell(org_name)="data">
                    {{data.item.org_name}}<br>
                    <small>Id:&nbsp;{{data.item.org_id}}</small>
                    <div>
                      <i class="fa-solid fa-file-lines pointer ml-2 mt-1 font-size-20" v-if="!orgId" title="View Event Report for School" @click="schoolSpecificList(data.item.org_id)"></i>
                    </div>
                  </template>

                  <template v-slot:cell(event_total_attendee_percent)="data">
                    {{data.item.event_total_regs !== 0 ? (data.item.event_total_attendees/ data.item.event_total_regs * 100).toFixed(2) : 0 }}%
                  </template>

                  <template v-slot:cell(sch_event_attendee_percent)="data">
                    {{data.item.sch_event_regs !== 0 ? (data.item.sch_event_attendees/ data.item.sch_event_regs * 100).toFixed(2) : 0 }}%
                  </template>

                  <template v-slot:cell(event_start_ts)="data">
                    {{data.item.event_start_ts | dateFormatDDMMYYYYHHMMA}}
                  </template>

                  <template v-slot:cell(duration)="data">
                    {{data.item.duration | durationToDHM}}
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else-if="loadingFlag">
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1"></b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<style lang="scss" scoped>
.analyticCard {
  vertical-align: middle;
  color: #000;
  background: #3366CC;
}
.table-responsive{
  word-break: normal;
}
</style>
<script>
import { evantAnlytics } from "../../../FackApi/api/analytics/eventAnalytic"
import { socialvue } from "../../../config/pluginInit.js"
import userPermission from "../../../Utils/user_permission.js"

export default {
  name: "EventReportSchoolWiseDashboard",
  components: {
  },
  data () {
    return {
      apiName: "event_analytics_school_wise",
      cvCardTitle: "Event Report School Wise",
      cvSearchText: "Type to Search",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Event Report School Wise",
      sortBy: "name",
      sortDesc: false,
      columns: [],
      eventList: null,
      filter: null,
      loadingFlag: false,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      orgId: null,
      org_name: null,
      org_city: null,
      org_state: null,
      org_country: null,
      whereFilter: {
        dateRange: {
          startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
          endDate: new Date(new Date().setDate(new Date().getDate() + 1))
        }
      }
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    dateFilter () {
      return { ...this.$store.getters["DateFilter/selectedDateState"] }
    }
  },
  beforeMount () {
    if (this.userData.user_role == "USERROLE11117") {
      // Allowed
      return true
    }

    // Auth Access for Listed Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  async mounted () {
    if (this.userData.user_role == "USERROLE11117") {
      if (this.userData.modules && this.userData.modules.organisations && Object.keys(this.userData.modules.organisations).length > 0) {
        this.orgId = `${Object.keys(this.userData.modules.organisations)[0]}`
      }
      else {
        this.orgId = this.$route.params.orgId ? this.$route.params.orgId : this.orgId
      }
    }
    else if (this.userData.user_role == "USERROLE11111" || this.userData.user_role == "USERROLE11118") {
      this.orgId = this.$route.params.orgId ? this.$route.params.orgId : this.orgId
    }

    await this.getDateFilter()
    this.initilize()
  },
  methods: {
    /**
     * initialize
     */
    initilize () {
      switch (this.userData.user_role) {
        case "USERROLE11111": // Admin
        case "USERROLE11118": // Counsellor
          this.columns = [
            { label: "# ", key: "sno", class: "text-left align-text-top", sortable: true },
            { label: "Event", key: "event_name", class: "text-left align-text-top w-300px", sortable: true },
            { label: "Student Registered", key: "sch_event_regs", class: "text-left align-text-top ", sortable: true },
            { label: "Student Joinees", key: "sch_event_joins", class: "text-left align-text-top ", sortable: true },
            { label: "Student Attendees", key: "sch_event_attendees", class: "text-left align-text-top ", sortable: true },
            { label: "Student Attendee %", key: "sch_event_attendee_percent", class: "text-left align-text-top ", sortable: true },
            { label: "Event Registrations", key: "event_total_regs", class: "text-left align-text-top ", sortable: true },
            { label: "Event Joinees", key: "event_total_joins", class: "text-left align-text-top ", sortable: true },
            { label: "Event Attendees", key: "event_total_attendees", class: "text-left align-text-top ", sortable: true },
            { label: "Event Attendee % ", key: "event_total_attendee_percent", class: "text-left align-text-top ", sortable: true },
            { label: "Date", key: "event_start_ts", class: "text-left align-text-top ", sortable: true }
          ]
          break
        case "USERROLE11115": // Univ Admin
        case "USERROLE11116": // Orgs
        case "USERROLE11117": // School
          this.columns = [
            { label: "# ", key: "sno", class: "text-left align-text-top", sortable: true },
            { label: "Event", key: "event_name", class: "text-left align-text-top w-300px", sortable: true },
            { label: "Student Registered", key: "sch_event_regs", class: "text-left align-text-top ", sortable: true },
            { label: "Student Joinees", key: "sch_event_joins", class: "text-left align-text-top ", sortable: true },
            { label: "Student Attendees", key: "sch_event_attendees", class: "text-left align-text-top ", sortable: true },
            { label: "Student Attendee %", key: "sch_event_attendee_percent", class: "text-left align-text-top ", sortable: true },
            { label: "Event Registrations", key: "event_total_regs", class: "text-left align-text-top ", sortable: true },
            { label: "Event Joinees", key: "event_total_joins", class: "text-left align-text-top ", sortable: true },
            { label: "Total Attendees", key: "event_total_attendees", class: "text-left align-text-top ", sortable: true },
            { label: "Total Attendee % ", key: "event_total_attendee_percent", class: "text-left align-text-top ", sortable: true },
            { label: "Date", key: "event_start_ts", class: "text-left align-text-top ", sortable: true }
          ]
          break
        default:
          break
      }

      const organisationColObj = { label: "School", key: "org_name", class: "text-left align-text-top w-300px", sortable: true }
      if (!this.orgId) {
        // Add organisation ob at postion 1
        this.columns.splice(2, 0, organisationColObj)
      }

      socialvue.index()
      this.eventReportList()
    },
    /**
     * Goto Event
     */
    gotoEvent (eventId) {
      window.open(`/event/${eventId}`, "_blank")
    },
    /**
     * Get daterange from LS. If found then use this as default else use the onse set in the data section of the object
     */
    async getDateFilter () {
      let storeDate = await this.dateFilter
      if (storeDate && storeDate.dateRange) {
        this.whereFilter = storeDate
      }
    },
    /**
     * Setting Date Range in the LS
     */
    async setDateFilter () {
      let endDate = this.whereFilter.dateRange.endDate
      this.whereFilter.dateRange.endDate = new Date(new Date(endDate).setDate(new Date(endDate).getDate() + 1))
      let whereDateFilter = { dateRange: this.whereFilter.dateRange }
      await this.$store.commit("DateFilter/addSelectedDate", whereDateFilter)
    },
    /**
     * calling user_analytics and userlist function on date wise and storing value in store
     */
    async eventSchoolListDateWise () {
      this.setDateFilter()
      this.eventReportList()
    },
    /**
     * eventReportList
     */
    async eventReportList () {
      this.loadingFlag = true
      try {
        let eventObj = {
          filter: this.whereFilter
        }

        if (this.orgId) {
          eventObj.org_id = this.orgId
        }

        let eventReportListResp = await evantAnlytics.eventSchoolWiseAnalytics(this, eventObj)
        if (eventReportListResp.resp_status) {
          this.eventList = eventReportListResp.resp_data.data
          this.totalRows = eventReportListResp.resp_data.count

          if (this.orgId && this.eventList.length) {
            this.org_name = this.eventList[0].org_name
            this.org_city = this.eventList[0].org_city
            this.org_state = this.eventList[0].org_state
            this.org_country = this.eventList[0].org_country
          }
        }
        else {
          // this.eventList = []
          this.toastMsg = eventReportListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at eventReportList() and Exception:", err.message)
      }
      finally {
        this.loadingFlag = false
      }
    },

    /**
     * schoolSpecificList ()
     */
    schoolSpecificList (orgId) {
      this.orgId = orgId
      this.eventList = []
      this.initilize()
      this.$router.push(`/event_analytic_school/${orgId}`)
    },

    /**
     * goToEventParticipantDashboard
     */
    goToEventParticipantDashboard (event) {
      this.$router.push(`/event_participant_dashboard/${event.event_id}/SCH/${event.org_id}`)
    }
  }
}
</script>
